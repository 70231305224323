import QuickActionBar_floatingSkinComponent from '@wix/thunderbolt-elements/src/components/QuickActionBar/viewer/skinComps/FloatingSkin/floatingSkin.skin';
import QuickActionBar_floatingSkinController from '@wix/thunderbolt-elements/src/components/QuickActionBar/viewer/QuickActionBar.controller';


const QuickActionBar_floatingSkin = {
  component: QuickActionBar_floatingSkinComponent,
  controller: QuickActionBar_floatingSkinController
};


export const components = {
  ['QuickActionBar_floatingSkin']: QuickActionBar_floatingSkin
};

